import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function IPEChartComponent({ data, xAxisKey, yAxisKey, metric }) {

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisKey} />
        <YAxis unit={metric === "energy" ? " kWh" : " kW"} />
        <Tooltip />
        <Line
          type="linear"
          dataKey={yAxisKey}
          stroke="#8884d8" // Couleur de la ligne
          strokeWidth={2} // Largeur de la ligne
          dot={{ stroke: "#8884d8", strokeWidth: 2, r: 4 }} // Personnalisation des points
          activeDot={{ r: 6 }} // Point actif avec une plus grande taille
        />
      </LineChart>
    </ResponsiveContainer>
  );
}