import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function ChartComponent({ data, xAxisKey, yAxisKey, usageColors }) {
  if (!Array.isArray(yAxisKey)) {
    console.error("yAxisKey should be an array.");
    return null;
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisKey} />
        <YAxis unit=" kWh" />
        <Tooltip />
        {yAxisKey.map((category, index) => (
          <Line
            key={index}
            type="linear"
            dataKey={category}
            name={category}
            stroke={usageColors[category]} // Utilisation de la couleur correspondante à la catégorie
            strokeWidth={2}
            dot={{ stroke: usageColors[category], strokeWidth: 2, r: 4 }} // Utilisation de la couleur correspondante à la catégorie
            activeDot={{ r: 6 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}
