// import "./login.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import LoginForm from "./LoginForm";
// import RegisterForm from "./RegisterForm";

export default function Login(props) {

  return (
    <div className="min-h-screen flex flex-col text-base sm:text-3xl">
      <Header/>
      <div className="flex flex-auto h-full items-center justify-center p-4 ">
        <LoginForm
          setToken={props.setToken}
          setUser={props.setUser}
        />
        {/* <RegisterForm /> */}
      </div>
      <Footer/>
    </div>
  );
}