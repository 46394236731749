import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import IPEChartComponent from "../Chart/IPEChartComponent";

export default function Dashboard2(props) {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : props.user;

  // Données d'indicateurs de performance énergétique (IPÉ) (fausses pour l'exemple)
  const [data] = useState([
    { building: "Bâtiment 1", name: "08/04/24 11:38", "IPÉ de l'éclairage": 0.8, "IPÉ du chauffage": 0.7, "IPÉ de la ventilation": 0.6, "IPÉ du radiateur": 0.75 },
    { building: "Bâtiment 2", name: "08/04/24 12:38", "IPÉ de l'éclairage": 0.7, "IPÉ du chauffage": 0.65, "IPÉ de la ventilation": 0.5, "IPÉ du radiateur": 0.8 },
    { building: "Bâtiment 3", name: "08/04/24 13:38", "IPÉ de l'éclairage": 0.9, "IPÉ du chauffage": 0.75, "IPÉ de la ventilation": 0.8, "IPÉ du radiateur": 0.65 },
  ]);

  // Fonction pour exporter les données en CSV
  const exportCSV = (graphTitle, graphData) => {
    const csvData = graphData.map(item => ({ name: item.name, [graphTitle]: item[graphTitle] }));
    return <CSVLink data={csvData} filename={`data_${graphTitle}.csv`} className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out">Exporter CSV</CSVLink>;
  };

  // State pour la plage de dates
  const [startDate, setStartDate] = useState(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());

  // State pour la granularité
  const [granularity, setGranularity] = useState("hour");

  // Fonction pour gérer le changement de granularité
  const handleGranularityChange = (event) => {
    setGranularity(event.target.value);
  };

  // State pour les facteurs de chaque usage
  const [lightingFactor, setLightingFactor] = useState(localStorage.getItem("lightingFactor") ? parseFloat(localStorage.getItem("lightingFactor")) : 1);
  const [heatingFactor, setHeatingFactor] = useState(localStorage.getItem("heatingFactor") ? parseFloat(localStorage.getItem("heatingFactor")) : 1);
  const [ventilationFactor, setVentilationFactor] = useState(localStorage.getItem("ventilationFactor") ? parseFloat(localStorage.getItem("ventilationFactor")) : 1);
  const [radiatorFactor, setRadiatorFactor] = useState(localStorage.getItem("radiatorFactor") ? parseFloat(localStorage.getItem("radiatorFactor")) : 1);

  // State pour les unités personnalisées
  const [lightingUnit, setLightingUnit] = useState(localStorage.getItem("lightingUnit") || "");
  const [heatingUnit, setHeatingUnit] = useState(localStorage.getItem("heatingUnit") || "");
  const [ventilationUnit, setVentilationUnit] = useState(localStorage.getItem("ventilationUnit") || "");
  const [radiatorUnit, setRadiatorUnit] = useState(localStorage.getItem("radiatorUnit") || "");

  // Fonction pour valider et stocker le facteur et l'unité dans le localStorage
  const handleValidation = (factor, setFactor, unit, setUnit, localStorageKeyFactor, localStorageKeyUnit) => {
    localStorage.setItem(localStorageKeyFactor, factor.toString());
    localStorage.setItem(localStorageKeyUnit, unit);
  };

  // State pour le bâtiment sélectionné
  const [selectedBuilding, setSelectedBuilding] = useState("all");

  // Fonction pour gérer le changement de filtre de bâtiment
  const handleBuildingFilterChange = (event) => {
    setSelectedBuilding(event.target.value);
  };

  const [selectedMetrics, setSelectedMetrics] = useState(
    data.reduce((acc, item) => {
      acc[item.building] = "energy";
      return acc;
    }, {})
  );

  const handleMetricChange = (building, event) => {
    setSelectedMetrics({
      ...selectedMetrics,
      [building]: event.target.value
    });
  };

  // Filtrer les graphiques en fonction du bâtiment sélectionné
  const filteredData = selectedBuilding === "all" ? data : data.filter(item => item.building === selectedBuilding);

  // Fonction pour rechercher les données
  const handleSearch = () => {
    // Ici, vous pouvez mettre à jour vos données en fonction des dates sélectionnées et de la granularité
    console.log("Recherche avec les paramètres suivants :");
    console.log("Date de début :", startDate);
    console.log("Date de fin :", endDate);
    console.log("Granularité :", granularity);
  };

  return (
    <div className="min-h-screen bg-gray-200">
      <Header user={user} />
      <div className="flex-grow py-8 px-4">
        <h1 className="text-4xl lg:text-5xl font-extrabold text-center mb-12 text-gray-800">Tableau de bord des indicateurs de performance énergétique</h1>
        {/* Conteneur pour les sélecteurs de plage de dates et de granularité */}
        <div className="flex flex-col lg:flex-row items-center justify-center mb-4">
          <div className="flex flex-col items-center lg:mr-4 mb-4 lg:mb-0">
            <span className="mb-2 font-medium">Sélectionner une plage de dates :</span>
            <div className="flex flex-col items-center lg:flex-row lg:items-center">
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                locale={fr}
                dateFormat="dd/MM/yyyy"
                className="border rounded-md px-3 py-2 mb-3 lg:mb-0 lg:mr-3 focus:outline-none focus:border-blue-500"
              />
              <span className="text-gray-600 mx-3 lg:hidden">à</span>
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                locale={fr}
                dateFormat="dd/MM/yyyy"
                className="border rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
          <div className="flex flex-col items-center lg:mr-4 mb-4 lg:mb-0">
            <span className="mb-2 font-medium">Granularité :</span>
            <select value={granularity} onChange={handleGranularityChange} className="border rounded-md px-2 py-1">
              <option value="hour">Heures</option>
              <option value="day">Jours</option>
              <option value="week">Semaines</option>
              <option value="month">Mois</option>
              <option value="year">Années</option>
            </select>
          </div>
          <div className="flex flex-col items-center lg:mr-4 mb-4 lg:mb-0">
            <span className="mb-2 font-medium">Filtrer par bâtiment :</span>
            <select value={selectedBuilding} onChange={handleBuildingFilterChange} className="border rounded-md px-2 py-1">
              <option value="all">Tous les bâtiments</option>
              <option value="Bâtiment 1">Bâtiment 1</option>
              <option value="Bâtiment 2">Bâtiment 2</option>
              <option value="Bâtiment 3">Bâtiment 3</option>
            </select>
          </div>
          <button onClick={handleSearch} className="bg-blue-500 hover:bg-blue-600 text-white mt-8 font-bold py-2 px-4 rounded transition duration-300 ease-in-out">Rechercher</button>
        </div>
        {/* Conteneur pour les graphiques */}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
          {/* Affichez un graphique pour chaque indicateur de performance énergétique (IPÉ) pour chaque bâtiment */}
          {filteredData.map((buildingData, buildingIndex) => (
            ["IPÉ de l'éclairage", "IPÉ du chauffage", "IPÉ de la ventilation", "IPÉ du radiateur"].map((title, index) => {
              const graphTitle = `${buildingData.building} - ${title}`;
            

              return (
                <div key={`${buildingIndex}_${index}`} className="bg-white rounded-lg shadow-md p-6">
                  <h2 className="text-lg font-bold mb-4">{graphTitle}</h2>
                  <div className="flex justify-between mb-4">
                    <span className="font-medium">Métrique :</span>
                    <select value={selectedMetrics[buildingData.building]} onChange={(e) => handleMetricChange(buildingData.building, e)} className="border rounded-md px-2 py-1">
                      <option value="energy">Énergie (kWh)</option>
                      <option value="power">Puissance (kW)</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="font-medium">Facteur pour le calcul de l'IPÉ :</label>
                    <input
                      type="number"
                      value={title === "IPÉ de l'éclairage" ? lightingFactor : title === "IPÉ du chauffage" ? heatingFactor : title === "IPÉ de la ventilation" ? ventilationFactor : radiatorFactor}
                      onChange={(e) => {
                        switch (title) {
                          case "IPÉ de l'éclairage":
                            setLightingFactor(parseFloat(e.target.value));
                            break;
                          case "IPÉ du chauffage":
                            setHeatingFactor(parseFloat(e.target.value));
                            break;
                          case "IPÉ de la ventilation":
                            setVentilationFactor(parseFloat(e.target.value));
                            break;
                          case "IPÉ du radiateur":
                            setRadiatorFactor(parseFloat(e.target.value));
                            break;
                          default:
                            break;
                        }
                      }}
                      className="border rounded-md px-2 py-1 mt-2 mr-2"
                    />
                    <input
                      type="text"
                      value={title === "IPÉ de l'éclairage" ? lightingUnit : title === "IPÉ du chauffage" ? heatingUnit : title === "IPÉ de la ventilation" ? ventilationUnit : radiatorUnit}
                      onChange={(e) => {
                        switch (title) {
                          case "IPÉ de l'éclairage":
                            setLightingUnit(e.target.value);
                            break;
                          case "IPÉ du chauffage":
                            setHeatingUnit(e.target.value);
                            break;
                          case "IPÉ de la ventilation":
                            setVentilationUnit(e.target.value);
                            break;
                          case "IPÉ du radiateur":
                            setRadiatorUnit(e.target.value);
                            break;
                          default:
                            break;
                        }
                      }}
                      className="border rounded-md px-2 py-1 mt-2 w-12"
                    />
                    <button onClick={() => handleValidation(
                      title === "IPÉ de l'éclairage" ? lightingFactor : title === "IPÉ du chauffage" ? heatingFactor : title === "IPÉ de la ventilation" ? ventilationFactor : radiatorFactor,
                      title === "IPÉ de l'éclairage" ? setLightingFactor : title === "IPÉ du chauffage" ? setHeatingFactor : title === "IPÉ de la ventilation" ? setVentilationFactor : setRadiatorFactor,
                      title === "IPÉ de l'éclairage" ? lightingUnit : title === "IPÉ du chauffage" ? heatingUnit : title === "IPÉ de la ventilation" ? ventilationUnit : radiatorUnit,
                      title === "IPÉ de l'éclairage" ? setLightingUnit : title === "IPÉ du chauffage" ? setHeatingUnit : title === "IPÉ de la ventilation" ? setVentilationUnit : setRadiatorUnit,
                      title === "IPÉ de l'éclairage" ? "lightingFactor" : title === "IPÉ du chauffage" ? "heatingFactor" : title === "IPÉ de la ventilation" ? "ventilationFactor" : "radiatorFactor",
                      title === "IPÉ de l'éclairage" ? "lightingUnit" : title === "IPÉ du chauffage" ? "heatingUnit" : title === "IPÉ de la ventilation" ? "ventilationUnit" : "radiatorUnit"
                    )} className="ml-2 mt-2 bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded transition duration-300 ease-in-out">Valider</button>
                  </div>
                  <IPEChartComponent
                    title={graphTitle}
                    data={data.filter(d => d.building === buildingData.building)}
                    xAxisKey="name"
                    yAxisKey={title}
                    metric={selectedMetrics[buildingData.building]}
                  />
                  {exportCSV(graphTitle, data)}
                </div>
              );
            })
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
