import React, { useState } from "react";
import { IconContext } from "react-icons";
import { AiOutlineSetting, AiOutlineLeft } from "react-icons/ai";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { API_URL } from "../constants";
import logoALF from "./logoALF.png";
import logoICT from "./logoICT.png";
import axios from "axios";

export default function Header(props) {
  const [user, setUser] = useState(props.user);
  const location = useLocation();
  const navigate = useNavigate();

  const showMessagePopup = (message) => {
    alert(message);
  };

  const handleDeconnexion = async () => {
    try {
      const response = await axios.put(
        `${API_URL}/user/logout`,
        {
          userId: user.userId,
        },
        {
          headers: {
            mode: "cors",
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      );

      if (response.status === 401) {
        showMessagePopup(
          "ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer."
        );
        localStorage.clear();
        navigate.push("/login");
        return;
      }

      const json = response.data;
      console.log(json);
    } catch (error) {
      console.error(error.message);
    }
  };

  const logout = () => {
    setUser();
    handleDeconnexion()
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  return (
    <div className="bg-bleuSTB flex items-center flex-col justify-center sm:flex-row sm:justify-evenly text-white w-full">
      <div className="flex flex-row items-center mb-1 mt-1">
        {location.pathname !== "/login" && location.pathname !== "/home" && (
          <IconContext.Provider
            value={{
              className: "image",
              size: "2em",
              style: {
                marginTop: "auto",
                marginBottom: "auto",
              },
            }}
          >
            <AiOutlineLeft
              className="cursor-pointer hover:text-marronSTB text-2xl 3xl:text-5xl mr-5"
              onClick={() => window.history.back()}
            />
          </IconContext.Provider>
        )}
        <Link to={location.pathname === "/login" ? "#" : "/home"}>
          <img
            src={logoALF}
            alt="Logo"
            className="h-16 sm:h-20 md:h-28 3xl:h-40"
            style={{ pointerEvents: location.pathname === "/login" ? "none" : "auto" }}
          />
        </Link>
        <Link to={location.pathname === "/login" ? "#" : "/home"}>
          <img
            src={logoICT}
            alt="Logo STB"
            className="h-16 sm:h-20 md:h-28 3xl:h-40 ml-14"
            style={{ pointerEvents: location.pathname === "/login" ? "none" : "auto" }}
          />
        </Link>
      </div>
      <div className="flex flex-row items-center mb-5 sm:mb-0">
        {location.pathname !== "/login" && (
          <button
            className="bg-bleuCielSTB hover:bg-marronSTB text-white text-2xl rounded-2xl py-4 px-6 mr-4 cursor-pointer 3xl:text-4xl 3xl:px-10 3xl:py-10"
            onClick={logout}
          >
            Déconnexion
          </button>
        )}
        {location.pathname !== "/login" && (
          <IconContext.Provider
            value={{
              className: "image",
              size: "2em",
              style: {
                marginTop: "auto",
                marginBottom: "auto",
              },
            }}
          >
            <Link to="/admin">
              <AiOutlineSetting className="text-red hover:text-marronSTB text-2xl 3xl:text-5xl" />
            </Link>
          </IconContext.Provider>
        )}
      </div>
    </div>
  );
}
