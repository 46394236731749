import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SimpleChartComponent from "../Chart/SimpleChartComponent";
import ChartComponent from "../Chart/Chart";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";

export default function Dashboard1(props) {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : props.user;

  const [building1Data] = useState([
    { name: "08/04/24 11:38", "Bâtiment 1 - Consommation de l'éclairage": 30, "Bâtiment 1 - Consommation du chauffage": 20, "Bâtiment 1 - Consommation de la ventilation": 10, "Bâtiment 1 - Consommation du radiateur": 5 },
    { name: "08/04/24 12:38", "Bâtiment 1 - Consommation de l'éclairage": 40, "Bâtiment 1 - Consommation du chauffage": 25, "Bâtiment 1 - Consommation de la ventilation": 15, "Bâtiment 1 - Consommation du radiateur": 10 },
    { name: "08/04/24 13:38", "Bâtiment 1 - Consommation de l'éclairage": 35, "Bâtiment 1 - Consommation du chauffage": 35, "Bâtiment 1 - Consommation de la ventilation": 25, "Bâtiment 1 - Consommation du radiateur": 20 },
    { name: "08/04/24 14:38", "Bâtiment 1 - Consommation de l'éclairage": 50, "Bâtiment 1 - Consommation du chauffage": 30, "Bâtiment 1 - Consommation de la ventilation": 20, "Bâtiment 1 - Consommation du radiateur": 15 },
    { name: "08/04/24 15:38", "Bâtiment 1 - Consommation de l'éclairage": 45, "Bâtiment 1 - Consommation du chauffage": 40, "Bâtiment 1 - Consommation de la ventilation": 30, "Bâtiment 1 - Consommation du radiateur": 25 }
  ]);

  const [building2Data] = useState([
    { name: "08/04/24 11:38", "Bâtiment 2 - Consommation de l'éclairage": 25, "Bâtiment 2 - Consommation du chauffage": 15, "Bâtiment 2 - Consommation de la ventilation": 8, "Bâtiment 2 - Consommation du radiateur": 3 },
    { name: "08/04/24 12:38", "Bâtiment 2 - Consommation de l'éclairage": 35, "Bâtiment 2 - Consommation du chauffage": 20, "Bâtiment 2 - Consommation de la ventilation": 10, "Bâtiment 2 - Consommation du radiateur": 7 },
    { name: "08/04/24 13:38", "Bâtiment 2 - Consommation de l'éclairage": 30, "Bâtiment 2 - Consommation du chauffage": 30, "Bâtiment 2 - Consommation de la ventilation": 20, "Bâtiment 2 - Consommation du radiateur": 15 },
    { name: "08/04/24 14:38", "Bâtiment 2 - Consommation de l'éclairage": 45, "Bâtiment 2 - Consommation du chauffage": 25, "Bâtiment 2 - Consommation de la ventilation": 18, "Bâtiment 2 - Consommation du radiateur": 12 },
    { name: "08/04/24 15:38", "Bâtiment 2 - Consommation de l'éclairage": 40, "Bâtiment 2 - Consommation du chauffage": 35, "Bâtiment 2 - Consommation de la ventilation": 25, "Bâtiment 2 - Consommation du radiateur": 20 }
  ]);
  
  const [building3Data] = useState([
    { name: "08/04/24 11:38", "Bâtiment 3 - Consommation de l'éclairage": 35, "Bâtiment 3 - Consommation du chauffage": 18, "Bâtiment 3 - Consommation de la ventilation": 10, "Bâtiment 3 - Consommation du radiateur": 6 },
    { name: "08/04/24 12:38", "Bâtiment 3 - Consommation de l'éclairage": 45, "Bâtiment 3 - Consommation du chauffage": 23, "Bâtiment 3 - Consommation de la ventilation": 15, "Bâtiment 3 - Consommation du radiateur": 9 },
    { name: "08/04/24 13:38", "Bâtiment 3 - Consommation de l'éclairage": 40, "Bâtiment 3 - Consommation du chauffage": 28, "Bâtiment 3 - Consommation de la ventilation": 22, "Bâtiment 3 - Consommation du radiateur": 17 },
    { name: "08/04/24 14:38", "Bâtiment 3 - Consommation de l'éclairage": 55, "Bâtiment 3 - Consommation du chauffage": 33, "Bâtiment 3 - Consommation de la ventilation": 25, "Bâtiment 3 - Consommation du radiateur": 20 },
    { name: "08/04/24 15:38", "Bâtiment 3 - Consommation de l'éclairage": 50, "Bâtiment 3 - Consommation du chauffage": 38, "Bâtiment 3 - Consommation de la ventilation": 30, "Bâtiment 3 - Consommation du radiateur": 25 }
  ]);

  const exportCSV = (graphTitle, graphData) => {
    const csvData = graphData.map(item => ({ name: item.name, [graphTitle]: item[graphTitle] }));
    return <CSVLink data={csvData} filename={`data_${graphTitle}.csv`} className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out">Exporter CSV</CSVLink>;
  };

  const combinedData = building1Data.map((item, index) => ({
    name: item.name,
    "Bâtiment 1 - Consommation de l'éclairage ": item["Bâtiment 1 - Consommation de l'éclairage"],
    "Bâtiment 1 - Consommation du chauffage": item["Bâtiment 1 - Consommation du chauffage"],
    "Bâtiment 1 - Consommation de la ventilation": item["Bâtiment 1 - Consommation de la ventilation"],
    "Bâtiment 1 - Consommation du radiateur": item["Bâtiment 1 - Consommation du radiateur"],
    "Bâtiment 2 - Consommation de l'éclairage": building2Data[index] ? building2Data[index]["Bâtiment 2 - Consommation de l'éclairage"] : null,
    "Bâtiment 2 - Consommation du chauffage": building2Data[index] ? building2Data[index]["Bâtiment 2 - Consommation du chauffage"] : null,
    "Bâtiment 2 - Consommation de la ventilation": building2Data[index] ? building2Data[index]["Bâtiment 2 - Consommation de la ventilation"] : null,
    "Bâtiment 2 - Consommation du radiateur": building2Data[index] ? building2Data[index]["Bâtiment 2 - Consommation du radiateur"] : null,
    "Bâtiment 3 - Consommation de l'éclairage": building3Data[index] ? building3Data[index]["Bâtiment 3 - Consommation de l'éclairage"] : null,
    "Bâtiment 3 - Consommation du chauffage": building3Data[index] ? building3Data[index]["Bâtiment 3 - Consommation du chauffage"] : null,
    "Bâtiment 3 - Consommation de la ventilation": building3Data[index] ? building3Data[index]["Bâtiment 3 - Consommation de la ventilation"] : null,
    "Bâtiment 3 - Consommation du radiateur": building3Data[index] ? building3Data[index]["Bâtiment 3 - Consommation du radiateur"] : null,
  }));

  const yAxisKey = [
    "Bâtiment 1 - Consommation de l'éclairage",
    "Bâtiment 1 - Consommation du chauffage",
    "Bâtiment 1 - Consommation de la ventilation",
    "Bâtiment 1 - Consommation du radiateur",
    "Bâtiment 2 - Consommation de l'éclairage",
    "Bâtiment 2 - Consommation du chauffage",
    "Bâtiment 2 - Consommation de la ventilation",
    "Bâtiment 2 - Consommation du radiateur",
    "Bâtiment 3 - Consommation de l'éclairage",
    "Bâtiment 3 - Consommation du chauffage",
    "Bâtiment 3 - Consommation de la ventilation",
    "Bâtiment 3 - Consommation du radiateur"
  ];

  const usageColors = {
    "Bâtiment 1 - Consommation de l'éclairage": "#1f77b4",
    "Bâtiment 1 - Consommation du chauffage": "#ff7f0e",
    "Bâtiment 1 - Consommation de la ventilation": "#2ca02c",
    "Bâtiment 1 - Consommation du radiateur": "#d62728",
    "Bâtiment 2 - Consommation de l'éclairage": "#9467bd",
    "Bâtiment 2 - Consommation du chauffage": "#8c564b",
    "Bâtiment 2 - Consommation de la ventilation": "#e377c2",
    "Bâtiment 2 - Consommation du radiateur": "#7f7f7f",
    "Bâtiment 3 - Consommation de l'éclairage": "#bcbd22",
    "Bâtiment 3 - Consommation du chauffage": "#17becf",
    "Bâtiment 3 - Consommation de la ventilation": "#FF5733",
    "Bâtiment 3 - Consommation du radiateur": "#33FFB7"
  };

  const [startDate, setStartDate] = useState(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());
  const [granularity, setGranularity] = useState("hour");
  const [selectedBuilding, setSelectedBuilding] = useState("all");
  const [selectedUsage, setSelectedUsage] = useState("all");
  const [selectedMetrics, setSelectedMetrics] = useState(
    yAxisKey.reduce((acc, key) => {
      acc[key] = "energy";
      return acc;
    }, {})
  );

  const handleGranularityChange = (event) => {
    setGranularity(event.target.value);
  };

  const handleBuildingFilterChange = (event) => {
    setSelectedBuilding(event.target.value);
  };

  const handleUsageFilterChange = (event) => {
    setSelectedUsage(event.target.value);
  };

  const handleMetricChange = (key, event) => {
    setSelectedMetrics({
      ...selectedMetrics,
      [key]: event.target.value
    });
  };

  const handleSearch = () => {
    console.log("Recherche avec les paramètres suivants :");
    console.log("Date de début :", startDate);
    console.log("Date de fin :", endDate);
    console.log("Granularité :", granularity);
  };

  const filteredCombinedData = combinedData.map(item => ({
    name: item.name,
    ...Object.keys(item).reduce((acc, key) => {
      if (
        ((selectedBuilding === "all") ||
        (selectedBuilding === "building1" && key.startsWith("Bâtiment 1")) ||
        (selectedBuilding === "building2" && key.startsWith("Bâtiment 2")) ||
        (selectedBuilding === "building3" && key.startsWith("Bâtiment 3"))) &&
        ((selectedUsage === "all") ||
        (selectedUsage === "éclairage" && key.includes("éclairage")) ||
        (selectedUsage === "chauffage" && key.includes("chauffage")) ||
        (selectedUsage === "ventilation" && key.includes("ventilation")) ||
        (selectedUsage === "radiateur" && key.includes("radiateur")))
      ) {
        acc[key] = item[key];
      }
      return acc;
    }, {})
  }));

  return (
    <div className="min-h-screen bg-gray-200">
      <Header user={user} />
      <div className="flex-grow py-8 px-4">
        <h1 className="text-4xl lg:text-5xl font-extrabold text-center mb-12 text-gray-800">Tableau de bord des consommations énergétiques</h1>
        <div className="flex flex-col lg:flex-row items-center justify-center mb-4">
          <div className="flex flex-col items-center lg:mr-4 mb-4 lg:mb-0">
            <span className="mb-2 font-medium">Sélectionner une plage de dates :</span>
            <div className="flex flex-col items-center lg:flex-row lg:items-center">
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                locale={fr}
                dateFormat="dd/MM/yyyy"
                className="border rounded-md px-3 py-2 mb-3 lg:mb-0 lg:mr-3 focus:outline-none focus:border-blue-500"
              />
              <span className="text-gray-600 mx-3 lg:hidden">à</span>
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                locale={fr}
                dateFormat="dd/MM/yyyy"
                className="border rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
          <div className="flex flex-col items-center lg:mr-4 mb-4 lg:mb-0">
            <span className="mb-2 font-medium">Granularité :</span>
            <select value={granularity} onChange={handleGranularityChange} className="border rounded-md px-2 py-1">
              <option value="hour">Heures</option>
              <option value="day">Jours</option>
              <option value="week">Semaines</option>
              <option value="month">Mois</option>
              <option value="year">Années</option>
            </select>
          </div>
          <div className="flex flex-col items-center lg:mr-4 mb-4 lg:mb-0">
            <span className="mb-2 font-medium">Filtrer par bâtiment :</span>
            <select value={selectedBuilding} onChange={handleBuildingFilterChange} className="border rounded-md px-2 py-1">
              <option value="all">Tous les bâtiments</option>
              <option value="building1">Bâtiment 1</option>
              <option value="building2">Bâtiment 2</option>
              <option value="building3">Bâtiment 3</option>
            </select>
          </div>
          <div className="flex flex-col items-center lg:mr-4 mb-4 lg:mb-0">
            <span className="mb-2 font-medium">Filtrer par usage :</span>
            <select value={selectedUsage} onChange={handleUsageFilterChange} className="border rounded-md px-2 py-1">
              <option value="all">Tous les usages</option>
              <option value="éclairage">Éclairage</option>
              <option value="chauffage">Chauffage</option>
              <option value="ventilation">Ventilation</option>
              <option value="radiateur">Radiateur</option>
            </select>
          </div>
          <button onClick={handleSearch} className="bg-blue-500 hover:bg-blue-600 text-white mt-8 font-bold py-2 px-4 rounded transition duration-300 ease-in-out">Rechercher</button>
        </div>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
          {yAxisKey.map((key, index) => (
            (selectedBuilding === "all") ||
            (selectedBuilding === "building1" && key.startsWith("Bâtiment 1")) ||
            (selectedBuilding === "building2" && key.startsWith("Bâtiment 2")) ||
            (selectedBuilding === "building3" && key.startsWith("Bâtiment 3")) ?
            <div key={index} className={`bg-white rounded-lg shadow-md p-6 ${((selectedUsage !== "all" && !key.toLowerCase().includes(selectedUsage)) ? 'hidden' : '')}`}>
              <h2 className="text-lg font-bold mb-4">{key}</h2>
              <div className="flex justify-between mb-4">
                <span className="font-medium">Métrique :</span>
                <select value={selectedMetrics[key]} onChange={(e) => handleMetricChange(key, e)} className="border rounded-md px-2 py-1">
                  <option value="energy">Énergie (kWh)</option>
                  <option value="power">Puissance (kW)</option>
                </select>
              </div>
              {(selectedUsage === "all" || key.toLowerCase().includes(selectedUsage)) &&
                <SimpleChartComponent
                  title={key}
                  data={
                    key.startsWith("Bâtiment 1")
                      ? building1Data
                      : key.startsWith("Bâtiment 2")
                      ? building2Data
                      : building3Data
                  }
                  xAxisKey="name"
                  yAxisKey={key}
                  metric={selectedMetrics[key]}
                />
              }
              {exportCSV(
                key,
                key.startsWith("Bâtiment 1")
                  ? building1Data
                  : key.startsWith("Bâtiment 2")
                  ? building2Data
                  : building3Data
              )}
            </div> : null
          ))}
        </div>
        {((selectedBuilding === "all" || selectedUsage === "all")) ? (
          <div className="bg-white rounded-lg shadow-md p-6 mt-8">
            <h2 className="text-lg font-bold mb-4">Consommation de tous les usages</h2>
            <ChartComponent
              title="Toutes les données combinées"
              data={filteredCombinedData}
              xAxisKey="name"
              yAxisKey={Object.keys(filteredCombinedData[0]).filter(key => key !== "name")}
              usageColors={usageColors}
            />
            <div className="flex justify-center mt-4 flex-wrap">
              {selectedBuilding !== "building2" && selectedBuilding !== "building3" && (
                <div className="flex flex-col mb-4 md:mr-4">
                  <span className="text-lg font-semibold mb-2">Bâtiment 1</span>
                  {Object.keys(usageColors).map((key, index) => {
                    if (key.includes("Bâtiment 1") && (selectedUsage === "all" || key.includes(selectedUsage))) {
                      return (
                        <div className="flex items-center mb-2" key={index}>
                          <div className="w-4 h-4" style={{ backgroundColor: usageColors[key], borderRadius: '50%', marginRight: '0.5rem' }}></div>
                          <span className="text-sm font-medium">{key.split(" - ")[1]}</span>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {selectedBuilding !== "building1" && selectedBuilding !== "building3" && (
                <div className="flex flex-col mb-4 md:mr-4">
                  <span className="text-lg font-semibold mb-2">Bâtiment 2</span>
                  {Object.keys(usageColors).map((key, index) => {
                    if (key.includes("Bâtiment 2") && (selectedUsage === "all" || key.includes(selectedUsage))) {
                      return (
                        <div className="flex items-center mb-2" key={index}>
                          <div className="w-4 h-4" style={{ backgroundColor: usageColors[key], borderRadius: '50%', marginRight: '0.5rem' }}></div>
                          <span className="text-sm font-medium">{key.split(" - ")[1]}</span>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              {selectedBuilding !== "building1" && selectedBuilding !== "building2" && (
                <div className="flex flex-col mb-4">
                  <span className="text-lg font-semibold mb-2">Bâtiment 3</span>
                  {Object.keys(usageColors).map((key, index) => {
                    if (key.includes("Bâtiment 3") && (selectedUsage === "all" || key.includes(selectedUsage))) {
                      return (
                        <div className="flex items-center mb-2" key={index}>
                          <div className="w-4 h-4" style={{ backgroundColor: usageColors[key], borderRadius: '50%', marginRight: '0.5rem' }}></div>
                          <span className="text-sm font-medium">{key.split(" - ")[1]}</span>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
      <Footer />
    </div>
  );
}