import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Boitier from "./Boitier";

export default function Admin(props) {
  const [boitiers, setBoitiers] = useState([
    { id: 1, usage: "Éclairage", tension: "230V", reseau: "Monophasé", coefficient: 0.5 },
    { id: 2, usage: "Chauffage", tension: "400V", reseau: "Triphasé", coefficient: 1.0 },
    { id: 3, usage: "Ventilation", tension: "230V", reseau: "Monophasé", coefficient: 0.7 },
    { id: 4, usage: "Radiateur", tension: "400V", reseau: "Triphasé", coefficient: 0.8 },
  ]);

  const handleUpdateBoitier = (id, tension, reseau, coefficient, calibrationCurrent, calibrationVoltage) => {
    setBoitiers((prevState) =>
      prevState.map((boitier) =>
        boitier.id === id ? { ...boitier, tension, reseau, coefficient, calibrationCurrent, calibrationVoltage } : boitier
      )
    );
  };

  const handleDeleteBoitier = (id) => {
    setBoitiers((prevState) => prevState.filter((boitier) => boitier.id !== id));
  };

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <Header />
      <div className="flex-grow flex items-center justify-center p-6">
        <div className="max-w-6xl w-full">
          <h2 className="text-4xl lg:text-5xl font-extrabold text-center mb-12 text-gray-800">Préconfiguration des boîtiers</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {boitiers.map((boitier) => (
              <Boitier
                key={boitier.id}
                {...boitier}
                onUpdate={handleUpdateBoitier}
                onDelete={handleDeleteBoitier}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
