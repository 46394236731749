import { Routes, Route, Navigate } from 'react-router-dom';
import React from "react";
import { useState } from "react";

import Login from "./Login/Login.js";
import Mentions from "./Mentions legales/Mentions.js";
import Dashboard1 from "./Dashboard1/Dashboard1.js";
import Dashboard2 from './Dashboard2/Dashboard2.js';
import Home from './Home/Home.js';
import Admin from './Admin/Admin.js';

export const AppRoutes = () => {
  const [user, setUser] = useState(null);
  return (
    <Routes>
      <Route path="/" element={user ? <Navigate to="/dashboard1" /> : <Navigate to="/login" />}/>
      <Route path="/login" element={<Login setUser={setUser} />} />
      <Route path="/admin" element={<Admin setUser={setUser} />} />
      <Route path="/home" element={<Home setUser={setUser} />} />
      <Route path="/dashboard1" element={<Dashboard1 setUser={setUser} />} />
      <Route path="/dashboard2" element={<Dashboard2 setUser={setUser} />} />
      <Route path="/mentions-legales" element={<Mentions />} />
    </Routes>
  );
};