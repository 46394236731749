import React from 'react';
import { Link } from 'react-router-dom';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function Home(props) {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : props.user;

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <Header user={user} />
      <div className="flex-grow flex items-center justify-center">
        <div className="max-w-4xl w-full p-6">
          <h1 className="text-5xl font-extrabold text-center mb-12 text-gray-800">Bienvenue sur votre Tableau de Bord Énergétique</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <Link to="/dashboard1" className="bg-blue-50 rounded-lg shadow-lg p-8 text-center hover:bg-blue-100 transition duration-300 ease-in-out">
              <h2 className="text-xl font-bold mb-4 text-blue-800">Tableau de bord des consommations énergétiques</h2>
              <p className="text-gray-600">Visualisez les données de consommation énergétique avec des graphiques détaillés et exportez-les pour une analyse approfondie.</p>
            </Link>
            <Link to="/dashboard2" className="bg-green-50 rounded-lg shadow-lg p-8 text-center hover:bg-green-100 transition duration-300 ease-in-out">
              <h2 className="text-xl font-bold mb-4 text-green-800">Tableau de bord des indicateurs de performance énergétique</h2>
              <p className="text-gray-600">Explorez les indicateurs de performance énergétique (IPÉ) pour évaluer l'efficacité énergétique de différents systèmes.</p>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
