import React, { useState, useEffect } from "react";

const Boitier = ({ id, usage, tension, reseau, coefficient, onUpdate, onDelete }) => {
  const [editing, setEditing] = useState(false);
  const [editedTension, setEditedTension] = useState(tension);
  const [editedReseau, setEditedReseau] = useState(reseau);
  const [editedCoefficient, setEditedCoefficient] = useState(coefficient);
  const [editedSentCurrent, setEditedSentCurrent] = useState(0);
  const [editedMeasuredCurrent, setEditedMeasuredCurrent] = useState(0);
  const [editedSentVoltage, setEditedSentVoltage] = useState(0);
  const [editedMeasuredVoltage, setEditedMeasuredVoltage] = useState(0);
  const [calibrationCurrent, setCalibrationCurrent] = useState(0);
  const [calibrationVoltage, setCalibrationVoltage] = useState(0);
  const [error, setError] = useState("");

   useEffect(() => {
    
      const currentDiff = editedMeasuredCurrent - editedSentCurrent;
      const voltageDiff = editedMeasuredVoltage - editedSentVoltage;
      setCalibrationCurrent(currentDiff);
      setCalibrationVoltage(voltageDiff);
    
  }, [editedMeasuredCurrent,editedMeasuredVoltage,editedSentCurrent,editedSentVoltage]);

  const handleUpdate = () => {
    const coefficientValue = parseFloat(editedCoefficient);
    if (isNaN(coefficientValue) || coefficientValue <= 0 || coefficientValue > 1) {
      setError("Le facteur de puissance doit être un nombre supérieur à 0 et inférieur ou égal à 1.");
      return;
    }

    onUpdate(id, editedTension, editedReseau, editedCoefficient, calibrationCurrent, calibrationVoltage);
    setEditing(false);
    setError("");
  };

  useEffect(() => {
    setEditedTension(tension);
    setEditedReseau(reseau);
    setEditedCoefficient(coefficient);
  }, [tension, reseau, coefficient]);

  useEffect(() => {
    if (editedTension === "400") {
      setEditedReseau("Triphasé");
    } else if (editedTension === "230") {
      setEditedReseau("Monophasé");
    }
  }, [editedTension]);

  return (
    <div className="bg-white rounded-lg shadow-lg p-8 mb-6 transform transition-transform duration-300 hover:scale-105">
      <h3 className="text-2xl font-bold text-gray-800 mb-4">{usage}</h3>
      {editing ? (
        <div className="space-y-4">
          <div>
            <label htmlFor={`tension-${id}`} className="block text-sm font-semibold text-gray-600 mb-1">Tension:</label>
            <select
              id={`tension-${id}`}
              value={editedTension}
              onChange={(e) => setEditedTension(e.target.value)}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="230">230V</option>
              <option value="400">400V</option>
            </select>
          </div>
          <div>
            <label htmlFor={`reseau-${id}`} className="block text-sm font-semibold text-gray-600 mb-1">Réseau:</label>
            <select
              id={`reseau-${id}`}
              value={editedReseau}
              onChange={(e) => setEditedReseau(e.target.value)}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled
            >
              <option value="Monophasé">Monophasé</option>
              <option value="Triphasé">Triphasé</option>
            </select>
          </div>
          <div>
            <label htmlFor={`coefficient-${id}`} className="block text-sm font-semibold text-gray-600 mb-1">Facteur de puissance:</label>
            <input
              type="text"
              id={`coefficient-${id}`}
              placeholder="Coefficient"
              value={editedCoefficient}
              onChange={(e) => setEditedCoefficient(e.target.value)}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor={`sentCurrent-${id}`} className="block text-sm font-semibold text-gray-600 mb-1">Courant envoyé:</label>
            <input
              type="number"
              id={`sentCurrent-${id}`}
              placeholder="Courant envoyé"
              value={editedSentCurrent}
              onChange={(e) => setEditedSentCurrent(parseFloat(e.target.value))}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor={`measuredCurrent-${id}`} className="block text-sm font-semibold text-gray-600 mb-1">Courant mesuré:</label>
            <input
              type="number"
              id={`measuredCurrent-${id}`}
              placeholder="Courant mesuré"
              value={editedMeasuredCurrent}
              onChange={(e) => setEditedMeasuredCurrent(parseFloat(e.target.value))}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor={`sentVoltage-${id}`} className="block text-sm font-semibold text-gray-600 mb-1">Tension envoyée:</label>
            <input
              type="number"
              id={`sentVoltage-${id}`}
              placeholder="Tension envoyée"
              value={editedSentVoltage}
              onChange={(e) => setEditedSentVoltage(parseFloat(e.target.value))}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor={`measuredVoltage-${id}`} className="block text-sm font-semibold text-gray-600 mb-1">Tension mesurée:</label>
            <input
              type="number"
              id={`measuredVoltage-${id}`}
              placeholder="Tension mesurée"
              value={editedMeasuredVoltage}
              onChange={(e) => setEditedMeasuredVoltage(parseFloat(e.target.value))}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          {error && <div className="text-red-500 mt-2">{error}</div>}
          <div className="flex justify-end space-x-4">
            <button
              onClick={handleUpdate}
              className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Enregistrer
            </button>
            <button onClick={() => setEditing(false)} className="bg-gray-300 text-gray-700 px-6 py-2 rounded-lg hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500">
              Annuler
            </button>
          </div>
        </div>
      ) : (
        <div className="space-y-4 text-gray-700">
          <div><span className="font-semibold text-gray-800">Tension:</span> {tension}</div>
          <div><span className="font-semibold text-gray-800">Réseau:</span> {reseau}</div>
          <div><span className="font-semibold text-gray-800">Facteur de puissance:</span> {coefficient}</div>
          <div><span className="font-semibold text-gray-800">Courant envoyé:</span> {editedSentCurrent}</div>
          <div><span className="font-semibold text-gray-800">Courant mesuré:</span> {editedMeasuredCurrent}</div>
          <div><span className="font-semibold text-gray-800">Étalonnage courant:</span> {calibrationCurrent}</div>
          <div><span className="font-semibold text-gray-800">Tension envoyée:</span> {editedSentVoltage}</div>
          <div><span className="font-semibold text-gray-800">Tension mesurée:</span> {editedMeasuredVoltage}</div>
          <div><span className="font-semibold text-gray-800">Étalonnage tension:</span> {calibrationVoltage}</div>
          <div className="flex justify-end space-x-4">
            <button onClick={() => setEditing(true)} className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500">
              Modifier
            </button>
            <button onClick={() => onDelete(id)} className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500">
              Supprimer
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Boitier;
